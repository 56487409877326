import { FC } from 'react';
import { OverviewCardProps } from '@glise/bricks/HomePage/ProductOverview/OverviewCard/OverviewCard.props';
import styles from './OverviewCard.module.scss';
import Image from 'next/image';
import cn from 'classnames';

const OverviewCard: FC<OverviewCardProps> = ({
  image,
  title,
  description,
  blur = 'center',
  size = 'sm',
}) => {
  const imgSize = () => {
    if (size === 'lg') {
      return { width: 590, height: 298 };
    } else {
      return { width: 386, height: 298 };
    }
  };
  return (
    <div className={cn(styles.OverviewCard, styles[blur])}>
      <div className={cn(styles.ImageWrapper)}>
        <Image
          src={image}
          alt={`Overview - ${title}`}
          width={imgSize().width}
          height={imgSize().height}
          // quality={100}
          layout="responsive"
          crossOrigin={'anonymous'}
          // unoptimized
        />
      </div>
      <div className={styles.ContentWrapper}>
        <div className={styles.Title}>{title}</div>
        <div className={styles.Description}>{description}</div>
      </div>
    </div>
  );
};

export default OverviewCard;
