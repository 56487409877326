import styles from './Headline.module.scss';
import { HeadlineProps } from './Headline.props';
import cn from 'classnames';

function Headline({
  title,
  subtitle,
  className,
  tagline,
  style,
}: HeadlineProps) {
  return (
    <div
      className={cn(className, styles.TextContainer, style && styles[style])}
    >
      {tagline && <span className={styles.Tagline}>{tagline}</span>}
      <h1 className={styles.Title}>{title}</h1>
      {subtitle && <h2 className={styles.Subtitle}>{subtitle}</h2>}
    </div>
  );
}

export default Headline;
