import { PillsGroupProps } from '@glise/bricks/PillsGroup/PillsGroup.props';
import styles from './PillsGroup.module.scss';
import Pill from '@glise/components/common/Pill/Pill';
import React from 'react';
import cn from 'classnames';
import Link from 'next/link';

const PillsGroup = ({ options, style, size, fullWidth }: PillsGroupProps) => {
  return (
    <div
      className={cn(
        styles.PillsGroup,
        style && styles[style],
        fullWidth && styles.FullWidth,
      )}
    >
      {options.map((option) =>
        option.href ? (
          <Link href={option.href} key={option.name} aria-label={option.name}>
            <Pill
              onClick={option.onClick}
              selected={option.selected}
              style={style ?? 'purple-active'}
              size={size ?? 'md'}
            >
              {option.name}
            </Pill>
          </Link>
        ) : (
          <Pill
            key={option.name}
            onClick={option.onClick}
            selected={option.selected}
            style={style ?? 'purple-active'}
            size={size ?? 'md'}
          >
            {option.name}
          </Pill>
        ),
      )}
    </div>
  );
};

export default PillsGroup;
