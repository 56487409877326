import styles from './ProductOverview.module.scss';
import Headline from '@glise/components/common/Headline/Headline';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import OverviewCard from '@glise/bricks/HomePage/ProductOverview/OverviewCard/OverviewCard';
import Trans from 'next-translate/Trans';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { CLOUDFRONT_URL } from '@glise/utils/constants';

function ProductOverview() {
  const { t } = useTranslation('home');
  return (
    <Container id="product">
      <div className={styles.ProductOverviewContainer}>
        <Headline
          tagline={t('product.tagline')}
          title={
            <div>
              <Trans i18nKey="home:product.title" components={{ br: <br /> }} />
            </div>
          }
          subtitle={<div>{t('product.subtitle')}</div>}
        />
        <div className={styles.ProductOverviewWrapper}>
          <Grid container spacing={3}>
            {/* Row 1 */}
            <Grid item xs={12} sm={6} md={4}>
              <OverviewCard
                blur={'top-left'}
                image={`${CLOUDFRONT_URL}bi-illustrations/transcript.webp`}
                title={t('product.cards.insights.title')}
                description={t('product.cards.insights.description')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OverviewCard
                blur={'bottom-right'}
                image={`${CLOUDFRONT_URL}bi-illustrations/report-generation.webp`}
                title={t('product.cards.reports.title')}
                description={t('product.cards.reports.description')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <OverviewCard
                image={`${CLOUDFRONT_URL}bi-illustrations/automation.webp`}
                title={t('product.cards.followUp.title')}
                description={t('product.cards.followUp.description')}
              />
            </Grid>

            {/* Row 2 */}
            <Grid item xs={12} sm={6} md={6}>
              <OverviewCard
                blur={'top'}
                size={'lg'}
                image={`${CLOUDFRONT_URL}bi-illustrations/copilot.webp`}
                title={t('product.cards.copilot.title')}
                description={t('product.cards.copilot.description')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <OverviewCard
                size={'lg'}
                image={`${CLOUDFRONT_URL}bi-illustrations/management.webp`}
                title={t('product.cards.insightsDashboard.title')}
                description={t('product.cards.insightsDashboard.description')}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Container>
  );
}

export default ProductOverview;
