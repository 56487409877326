function CheckCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6665 18C12.7882 18 14.8231 17.1571 16.3234 15.6569C17.8236 14.1566 18.6665 12.1217 18.6665 10C18.6665 7.87827 17.8236 5.84344 16.3234 4.34315C14.8231 2.84285 12.7882 2 10.6665 2C8.54477 2 6.50994 2.84285 5.00965 4.34315C3.50936 5.84344 2.6665 7.87827 2.6665 10C2.6665 12.1217 3.50936 14.1566 5.00965 15.6569C6.50994 17.1571 8.54477 18 10.6665 18ZM14.5235 8.191C14.5814 8.11129 14.6231 8.02095 14.6461 7.92514C14.6691 7.82933 14.673 7.72994 14.6576 7.63262C14.6421 7.5353 14.6077 7.44198 14.5562 7.35797C14.5047 7.27396 14.4372 7.20091 14.3575 7.143C14.2778 7.08509 14.1875 7.04344 14.0916 7.02044C13.9958 6.99744 13.8964 6.99354 13.7991 7.00895C13.7018 7.02437 13.6085 7.0588 13.5245 7.11028C13.4405 7.16176 13.3674 7.22929 13.3095 7.309L9.8265 12.099L7.9465 10.219C7.87729 10.1474 7.7945 10.0903 7.70298 10.051C7.61146 10.0118 7.51303 9.99114 7.41345 9.99032C7.31386 9.9895 7.21511 10.0085 7.12296 10.0463C7.03081 10.084 6.94709 10.1398 6.87671 10.2102C6.80632 10.2807 6.75067 10.3644 6.713 10.4566C6.67534 10.5488 6.65641 10.6476 6.65732 10.7472C6.65823 10.8467 6.67897 10.9451 6.71832 11.0366C6.75766 11.1281 6.81484 11.2108 6.8865 11.28L9.3865 13.78C9.46313 13.8567 9.55547 13.9158 9.65715 13.9534C9.75883 13.9909 9.86745 14.006 9.97552 13.9975C10.0836 13.9891 10.1885 13.9573 10.2831 13.9044C10.3777 13.8515 10.4597 13.7787 10.5235 13.691L14.5235 8.191Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6665 18C12.7882 18 14.8231 17.1571 16.3234 15.6569C17.8236 14.1566 18.6665 12.1217 18.6665 10C18.6665 7.87827 17.8236 5.84344 16.3234 4.34315C14.8231 2.84285 12.7882 2 10.6665 2C8.54477 2 6.50994 2.84285 5.00965 4.34315C3.50936 5.84344 2.6665 7.87827 2.6665 10C2.6665 12.1217 3.50936 14.1566 5.00965 15.6569C6.50994 17.1571 8.54477 18 10.6665 18ZM14.5235 8.191C14.5814 8.11129 14.6231 8.02095 14.6461 7.92514C14.6691 7.82933 14.673 7.72994 14.6576 7.63262C14.6421 7.5353 14.6077 7.44198 14.5562 7.35797C14.5047 7.27396 14.4372 7.20091 14.3575 7.143C14.2778 7.08509 14.1875 7.04344 14.0916 7.02044C13.9958 6.99744 13.8964 6.99354 13.7991 7.00895C13.7018 7.02437 13.6085 7.0588 13.5245 7.11028C13.4405 7.16176 13.3674 7.22929 13.3095 7.309L9.8265 12.099L7.9465 10.219C7.87729 10.1474 7.7945 10.0903 7.70298 10.051C7.61146 10.0118 7.51303 9.99114 7.41345 9.99032C7.31386 9.9895 7.21511 10.0085 7.12296 10.0463C7.03081 10.084 6.94709 10.1398 6.87671 10.2102C6.80632 10.2807 6.75067 10.3644 6.713 10.4566C6.67534 10.5488 6.65641 10.6476 6.65732 10.7472C6.65823 10.8467 6.67897 10.9451 6.71832 11.0366C6.75766 11.1281 6.81484 11.2108 6.8865 11.28L9.3865 13.78C9.46313 13.8567 9.55547 13.9158 9.65715 13.9534C9.75883 13.9909 9.86745 14.006 9.97552 13.9975C10.0836 13.9891 10.1885 13.9573 10.2831 13.9044C10.3777 13.8515 10.4597 13.7787 10.5235 13.691L14.5235 8.191Z"
        fill="url(#paint0_linear_34_9236)"
        fillOpacity="0.12"
      />
      <defs>
        <linearGradient
          id="paint0_linear_34_9236"
          x1="10.6665"
          y1="2"
          x2="10.6665"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18181A" stopOpacity="0" />
          <stop offset="1" stopColor="#18181A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CheckCircleIcon;
