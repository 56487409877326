import styles from './Hero.module.scss';
import Image from 'next/image';
import Container from '@mui/material/Container';
import FormInput from '@glise/components/common/FormInput/FormInput';
import MessageIcon from '@glise/icons/MessageIcon';
import Button from '@glise/components/common/Button/Button';
import ArrowIcon from '@glise/icons/ArrowIcon';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_URL } from '@glise/api/helpers';
import Toast from '@glise/components/common/Toast/Toast';
import { SubscribeFormValues } from '@glise/bricks/Footer/Footer';
import useTranslation from 'next-translate/useTranslation';
import { CLOUDFRONT_URL } from '@glise/utils/constants';

function Hero() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<SubscribeFormValues>();

  const { t } = useTranslation('home');

  const subscribe = handleSubmit(async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    setIsProcessing(true);

    try {
      const token = await executeRecaptcha('subscribe');

      await axios.post(`${API_URL}api/v1/email/subscribe`, {
        email: data.email,
        ['g-recaptcha-response']: token,
      });

      Toast({
        type: 'success',
        label: t('common:notifications.success'),
        message: t('common:notifications.subscribed'),
      });

      reset();
    } catch (error: any) {
      console.log(error);
      // Handle errors
      Toast({
        type: 'error',
        label: t('common:notifications.error'),
        message:
          error?.response?.data?.message ||
          t('common:notifications.subscribeFailed'),
      });
    } finally {
      setIsProcessing(false);
    }
  });

  return (
    <Container>
      <div className={styles.HeroContentWrapper} id={'subscribe'}>
        <div className={styles.HeroTextContainer}>
          <h1 className={styles.BigText}>{t('hero.title')}</h1>
          <h2 className={styles.SmallText}>{t('hero.subtitle')}</h2>
          <form className={styles.InputContainer} onSubmit={subscribe}>
            <div className={styles.Launch}>
              <h3 className={styles.Title}>{t('hero.subscribeForm.title')}</h3>
              <div className={styles.SubscribeInputWrapper}>
                <FormInput
                  startCustomComponent={<MessageIcon />}
                  startCustomComponentClassName={styles.MessageIcon}
                  className={styles.SubscribeInput}
                  formInputClassName={styles.SubscribeInputContainer}
                  breakpoint={'md'}
                  placeholder={t('hero.subscribeForm.email.placeholder')}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: t('hero.subscribeForm.email.invalidEmail'),
                    },
                  })}
                  error={errors.email?.message}
                  // errorText
                />
                <Button
                  breakpoint={'lg'}
                  endIcon={<ArrowIcon />}
                  type={'submit'}
                  disabled={isProcessing}
                  className={styles.SubscribeButton}
                  aria-label={t('hero.subscribeForm.button.title')}
                >
                  {t('hero.subscribeForm.button.title')}
                </Button>
              </div>
            </div>
          </form>
          {/*<Link href="/contact">*/}
          {/*  <Button*/}
          {/*    className={styles.Button}*/}
          {/*    color="blue"*/}
          {/*    breakpoint={'lg'}*/}
          {/*    endIcon={<ArrowIcon />}*/}
          {/*  >*/}
          {/*    Try for free*/}
          {/*  </Button>*/}
          {/*</Link>*/}
        </div>
        <div className={styles.DashboardImage}>
          <Image
            src={`${CLOUDFRONT_URL}dashboard.webp`}
            alt="Glise Dashboard"
            width={996}
            height={640}
            crossOrigin={'anonymous'}
            layout="responsive"
            priority
            // quality={100}
            // unoptimized
          />
        </div>
      </div>
    </Container>
  );
}

export default Hero;
