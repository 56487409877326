import { FC } from 'react';
import { ChromeExtensionProps } from '@glise/bricks/HomePage/ChromeExtension/ChromeExtension.props';
import Container from '@mui/material/Container';
import styles from './ChromeExtension.module.scss';
import Headline from '@glise/components/common/Headline/Headline';
import Image from 'next/image';
import Button from '@glise/components/common/Button/Button';
import GoogleChromeIcon from '@glise/icons/GoogleChromeIcon';
import CustomTooltip from '@glise/components/common/CustomTooltip/CustomTooltip';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { CLOUDFRONT_URL } from '@glise/utils/constants';

const ChromeExtension: FC<ChromeExtensionProps> = () => {
  const { t } = useTranslation('home');
  return (
    <Container>
      <div className={styles.ChromeExtension}>
        <div className={styles.Content}>
          <Headline
            tagline={t('chromeExtension.tagline')}
            title={
              <div>
                <Trans
                  i18nKey="home:chromeExtension.title"
                  components={{ br: <br /> }}
                />
              </div>
            }
            subtitle={t('chromeExtension.subtitle')}
            style={'side'}
          />
          <CustomTooltip title={t('chromeExtension.button.tooltip')}>
            <div className={styles.ActionButtonWrapper}>
              <Button
                startIcon={<GoogleChromeIcon />}
                className={styles.ActionButton}
                breakpoint={'lg'}
                disabled
                aria-label={t('chromeExtension.button.label')}
              >
                {t('chromeExtension.button.label')}
              </Button>
            </div>
          </CustomTooltip>
        </div>
        <div className={styles.Preview}>
          <Image
            src={`${CLOUDFRONT_URL}extension.webp`}
            alt={'Glise - Browser Extension'}
            width={447}
            height={373}
            // quality={100}
            crossOrigin={'anonymous'}
            layout="responsive"
            // unoptimized
          />
        </div>
      </div>
    </Container>
  );
};

export default ChromeExtension;
