import styles from './Faq.module.scss';
import Headline from '@glise/components/common/Headline/Headline';
import Container from '@mui/material/Container';
import FaqItem from '@glise/bricks/HomePage/Faq/FaqItem/FaqItem';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

export interface SingleFaqProps {
  answer: string;
  id: number;
  order: number;
  question: string;
}

interface FaqProps {
  faqArr: SingleFaqProps[];
}

function Faq({ faqArr }: FaqProps) {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState<null | number>(null);

  // console.log('FAQ Array:', faqArr);

  const handleOpenDropdown = (index: number) => {
    if (index === isOpen) {
      setIsOpen(null);
    } else {
      setIsOpen(index);
    }
  };

  return (
    <Container id="faq">
      <div className={styles.FaqContainer}>
        <div>
          <Headline title={t('faq.title')} subtitle={t('faq.subtitle')} />
        </div>
        <div className={styles.FaqWrapper}>
          {faqArr &&
            faqArr.length > 0 &&
            faqArr.map((faq) => (
              <FaqItem
                key={faq.id}
                faqItem={faq}
                handleOpenDropdown={handleOpenDropdown}
                active={isOpen}
              />
            ))}
        </div>
      </div>
    </Container>
  );
}

export default Faq;
