const MessageIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.41832 4.16344C2.88626 3.6955 3.52093 3.43262 4.18269 3.43262H15.8173C16.4791 3.43262 17.1137 3.6955 17.5817 4.16344C18.0496 4.63138 18.3125 5.26604 18.3125 5.92781V6.11621V14.072C18.3125 14.7338 18.0496 15.3685 17.5817 15.8364C17.1137 16.3043 16.4791 16.5672 15.8173 16.5672H4.18269C3.52093 16.5672 2.88626 16.3043 2.41832 15.8364C1.95039 15.3685 1.6875 14.7338 1.6875 14.072V6.11754V5.92781C1.6875 5.26604 1.95039 4.63138 2.41832 4.16344ZM3.1875 5.92781C3.1875 5.66387 3.29235 5.41074 3.47899 5.2241C3.66562 5.03747 3.91875 4.93262 4.18269 4.93262H15.8173C16.0812 4.93262 16.3344 5.03747 16.521 5.2241C16.7076 5.41074 16.8125 5.66387 16.8125 5.92781V6.11629C16.8125 6.11622 16.8125 6.11636 16.8125 6.11629C16.8125 6.28613 16.769 6.45344 16.6862 6.60172C16.6033 6.75006 16.4839 6.87473 16.3392 6.96384L10.5222 10.5432C10.3653 10.6399 10.1843 10.6913 10 10.6913C9.81569 10.6913 9.635 10.6401 9.47808 10.5434L3.66077 6.96461C3.51625 6.87552 3.39659 6.75072 3.31381 6.60249C3.231 6.45421 3.18752 6.2872 3.1875 6.11737C3.1875 6.1173 3.1875 6.11744 3.1875 6.11737V5.92781ZM3.1875 8.43458V14.072C3.1875 14.336 3.29235 14.5891 3.47899 14.7757C3.66562 14.9624 3.91875 15.0672 4.18269 15.0672H15.8173C16.0812 15.0672 16.3344 14.9624 16.521 14.7757C16.7076 14.5891 16.8125 14.336 16.8125 14.072V8.43385L11.3086 11.8206C10.9152 12.0628 10.462 12.1913 10 12.1913C9.53803 12.1913 9.08513 12.063 8.69176 11.8208C8.69164 11.8207 8.69187 11.8209 8.69176 11.8208L3.1875 8.43458Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MessageIcon;
