import { useEffect, useState } from 'react';

function useDeviceSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
    isMobileView: boolean;
    isTabletView: boolean;
    isSmallDesktop: boolean;
  }>({
    width: 0,
    height: 0,
    isMobileView: false,
    isTabletView: false,
    isSmallDesktop: false,
  });

  useEffect(() => {
    // Function to handle resizing and set window size and device type
    const handleResize = () => {
      const width = window.innerWidth;

      setWindowSize({
        width: width,
        height: window.innerHeight,
        isMobileView: width <= 768, // Mobile view for devices <= 768px
        isTabletView: width > 768 && width <= 1024, // Tablet view for devices between 769px and 1024px
        isSmallDesktop: width > 1024 && width <= 1280,
      });
    };

    // Set initial size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that the effect is only run on mount and unmount

  return windowSize;
}

export default useDeviceSize;
