import React from 'react';
import { CustomTooltipProps } from './CustomTooltip.props';
import Tooltip from '@mui/material/Tooltip';
import styles from './CustomTooltip.module.scss';
import cn from 'classnames';

const CustomTooltip = ({
  title,
  children,
  position = 'top',
  breakpoint = 'md',
  ...rest
}: CustomTooltipProps) => {
  return (
    <Tooltip
      title={title}
      placement={position}
      {...rest}
      classes={{ tooltip: cn(styles.TooltipContent, styles[breakpoint]) }}
      // className={styles.TooltipContent}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
