import { PricingPlanProps } from './PricingPlan.props';
import styles from './PricingPlan.module.scss';
import cn from 'classnames';
import CheckCircleIcon from '@glise/icons/CheckCircleIcon';
import { useRouter } from 'next/router';
import React from 'react';
import _ from 'lodash';
import Button from '@glise/components/common/Button/Button';
import useTranslation from 'next-translate/useTranslation';

function PricingPlan({
  plan,
  billingType,
  popular,
  startNow,
  previousPlan,
}: PricingPlanProps) {
  const router = useRouter();
  const { t } = useTranslation('pricing');

  const { name, price, order, advantages, description, yearly_price } = plan;

  const priceNumber = _.endsWith(price, '.00')
    ? Math.floor(Number(price))
    : Number(price);
  const priceYearlyNumber = _.endsWith(yearly_price, '.00')
    ? Math.floor(Number(yearly_price))
    : Number(yearly_price);

  const calculatedPrice =
    billingType === t('common:plans.billingTypes.monthly')
      ? priceNumber
      : priceYearlyNumber;

  const advantagesArray = JSON.parse(advantages);

  const buttonColor = () => {
    switch (order) {
      case 1:
        return 'gray';
      default:
        return 'blue';
    }
  };

  return (
    <div className={cn(styles.Border, popular && styles.Purple)}>
      <div className={cn(styles.PricingPlanContainer, popular && styles.blue)}>
        <div className={styles.PricingPlanInfo}>
          <div className={styles.PlanName}>
            <div>{name}</div>
            {description ? (
              <div className={cn(styles.PricingDescription)}>
                {/*<LargeGroupIcon />*/}
                {description}
              </div>
            ) : (
              <div className={cn(styles.PricingDescription)} />
            )}
          </div>
          <div
            className={cn(
              styles.PricingPlanPrice,
              startNow && styles.StartNowPrice,
            )}
          >
            <div className={styles.PricingCost}>
              ${calculatedPrice} {t('pricingPlan.perSeat')}{' '}
              {billingType === t('common:plans.billingTypes.annually') && (
                <span className={styles.Discount}>-20%</span>
              )}
            </div>
          </div>
          {startNow && (
            <div className={styles.ButtonWrapper}>
              <Button
                breakpoint={'lg'}
                color={buttonColor()}
                aria-label={
                  name === 'Basic'
                    ? t('pricingPlan.tryForFree')
                    : t('pricingPlan.startNow')
                }
              >
                {name === 'Basic'
                  ? t('pricingPlan.tryForFree')
                  : t('pricingPlan.startNow')}
              </Button>
            </div>
          )}
        </div>
        {advantagesArray && advantagesArray.length > 0 && (
          <div className={styles.PricingPlanAdvantages}>
            {previousPlan ? (
              <div>
                {t('pricingPlan.includedPlus', {
                  previousPlan: previousPlan?.name,
                })}
              </div>
            ) : (
              <div>{t('pricingPlan.keyFeatures')}</div>
            )}
            {advantagesArray.map((advantage: string) => (
              <div key={advantage} className={styles.Advantage}>
                <CheckCircleIcon />
                {advantage}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default PricingPlan;
