import Image from 'next/image';
import styles from './ConnectTools.module.scss';
import Headline from '@glise/components/common/Headline/Headline';
import useDeviceSize from '@glise/hooks/useDeviceSize';
import Container from '@mui/material/Container';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { CLOUDFRONT_URL } from '@glise/utils/constants';

function ConnectTools() {
  const { width } = useDeviceSize();
  const { t } = useTranslation('home');
  return (
    <Container>
      <div className={styles.ConnectToolsContainer}>
        <div className={styles.IntegrationsImageWrapper}>
          <Image
            className={styles.IntegrationsImageWrapper}
            src={`${CLOUDFRONT_URL}integrations.webp`}
            alt={`Glise - ${t('connectTools.tagline')}`}
            width={594}
            height={440}
            crossOrigin={'anonymous'}
            layout="responsive"
            // quality={100}
            // unoptimized
          />
        </div>
        <Headline
          style={width > 1280 ? 'side' : undefined}
          className={styles.WrapperTagline}
          tagline={t('connectTools.tagline')}
          title={
            <div>
              <Trans
                i18nKey="home:connectTools.title"
                components={{ br: <br /> }}
              />
            </div>
          }
          subtitle={t('connectTools.subtitle')}
        />
      </div>
    </Container>
  );
}

export default ConnectTools;
