import { Button } from '@mui/material';
import { PillProps } from './Pill.props';
import cn from 'classnames';
import styles from './Pill.module.scss';

function Pill({
  children,
  className,
  onClick,
  selected,
  style,
  size,
}: PillProps) {
  const classNames = cn(
    className,
    styles.PillButton,
    selected && styles.Selected,
    style && styles[style],
    size && styles[size],
  );

  function handleClick() {
    onClick && onClick();
  }

  return (
    <Button className={classNames} onClick={handleClick} disableRipple>
      {children}
    </Button>
  );
}

export default Pill;
