const GoogleChromeIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2986_54314)">
        <path
          d="M15.2917 10.4978C15.2917 13.1442 13.1464 15.2895 10.5 15.2895C7.85364 15.2895 5.70833 13.1442 5.70833 10.4978C5.70833 7.85144 7.85364 5.70614 10.5 5.70614C13.1464 5.70614 15.2917 7.85144 15.2917 10.4978Z"
          fill="white"
          stroke="white"
          strokeWidth="0.416667"
        />
        <path
          d="M10.4993 5.50002H19.1581C18.2807 3.97976 17.0185 2.71731 15.4984 1.8396C13.9783 0.961898 12.2539 0.499878 10.4986 0.5C8.74335 0.500122 7.01903 0.962383 5.49906 1.8403C3.97909 2.71822 2.71705 3.98085 1.83984 5.50123L6.16926 13L6.17314 12.999C5.73272 12.2395 5.5003 11.3774 5.49932 10.4995C5.49833 9.62153 5.72881 8.75885 6.16751 7.99839C6.60622 7.23794 7.23766 6.60657 7.99816 6.16795C8.75867 5.72932 9.62137 5.49894 10.4993 5.50002Z"
          fill="url(#paint0_linear_2986_54314)"
        />
        <path
          d="M10.4993 14.4584C12.6855 14.4584 14.4577 12.6862 14.4577 10.5001C14.4577 8.31395 12.6855 6.54175 10.4993 6.54175C8.31322 6.54175 6.54102 8.31395 6.54102 10.5001C6.54102 12.6862 8.31322 14.4584 10.4993 14.4584Z"
          fill="#1A73E8"
        />
        <path
          d="M14.8293 13.0011L10.4998 20.4999C12.2551 20.5002 13.9795 20.0383 15.4997 19.1607C17.0198 18.2831 18.2821 17.0207 19.1597 15.5005C20.0372 13.9804 20.499 12.2559 20.4987 10.5006C20.4984 8.74536 20.036 7.02109 19.1579 5.50122H10.4991L10.498 5.5051C11.376 5.5034 12.2388 5.73319 12.9997 6.1713C13.7605 6.6094 14.3923 7.24034 14.8316 8.0005C15.2708 8.76067 15.5018 9.62319 15.5014 10.5011C15.501 11.3791 15.2692 12.2414 14.8293 13.0011Z"
          fill="url(#paint1_linear_2986_54314)"
        />
        <path
          d="M6.16955 13.0012L1.84013 5.50244C0.962268 7.02244 0.500065 8.74678 0.5 10.5021C0.499935 12.2574 0.962009 13.9817 1.83976 15.5018C2.71751 17.0219 3.98 18.284 5.50028 19.1614C7.02057 20.0388 8.74505 20.5004 10.5003 20.4999L14.8298 13.0011L14.827 12.9983C14.3895 13.7595 13.759 14.3918 12.9992 14.8316C12.2394 15.2715 11.377 15.5032 10.4991 15.5035C9.62117 15.5038 8.75867 15.2726 7.99856 14.8333C7.23844 14.394 6.60758 13.7621 6.16955 13.0012Z"
          fill="url(#paint2_linear_2986_54314)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2986_54314"
          x1="1.83984"
          y1="6.75002"
          x2="19.1581"
          y2="6.75002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D93025" />
          <stop offset="1" stopColor="#EA4335" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2986_54314"
          x1="9.13367"
          y1="20.3662"
          x2="17.7928"
          y2="5.36814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCC934" />
          <stop offset="1" stopColor="#FBBC04" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2986_54314"
          x1="11.5818"
          y1="19.8756"
          x2="2.92267"
          y2="4.87744"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E8E3E" />
          <stop offset="1" stopColor="#34A853" />
        </linearGradient>
        <clipPath id="clip0_2986_54314">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleChromeIcon;
