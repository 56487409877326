import { useState } from 'react';
import styles from './PricingPlans.module.scss';
import { PricingPlanComponentProps } from './PricingPlans.props';
import Headline from '@glise/components/common/Headline/Headline';
import PricingPlan from '@glise/components/common/PricingPlan/PricingPlan';
import { BillingType } from '@glise/components/common/PricingPlan/PricingPlan.props';
import PillsGroup from '@glise/bricks/PillsGroup/PillsGroup';
import { PillsGroupProps } from '@glise/bricks/PillsGroup/PillsGroup.props';
import useTranslation from 'next-translate/useTranslation';

function PricingPlans({ startNow, pricingPlans }: PricingPlanComponentProps) {
  const { t } = useTranslation('common');
  const [billingType, setBillingType] = useState<number>(2);

  const billingOptions: PillsGroupProps['options'] = [
    {
      id: 1,
      name: t('plans.billingTypes.monthly'),
      onClick: () => setBillingType(1),
      selected: billingType === 1,
    },
    {
      id: 2,
      name: t('plans.billingTypes.annually'),
      onClick: () => setBillingType(2),
      selected: billingType === 2,
    },
  ];
  return (
    // <Container>
    <div className={styles.PricingContainer} id="pricing">
      <Headline title={t('plans.title')} subtitle={t('plans.subtitle')} />
      <PillsGroup options={billingOptions} />
      <div className={styles.PricingPlansWrapper}>
        {pricingPlans &&
          pricingPlans.map((pricingPlan, index) => (
            <PricingPlan
              key={pricingPlan.id}
              plan={pricingPlan}
              billingType={
                (billingOptions.find((option) => option.id === billingType)
                  ?.name as BillingType) ?? 'Annually'
              }
              previousPlan={pricingPlans[index - 1]}
              popular={pricingPlan.name === 'Pro'}
              startNow={startNow}
            />
          ))}
      </div>
    </div>
    // </Container>
  );
}

export default PricingPlans;
