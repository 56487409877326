import React, { FC, ReactElement, useState } from 'react';
import { FeaturesProps } from '@glise/bricks/HomePage/Features/Features.props';
import Container from '@mui/material/Container';
import styles from './Features.module.scss';
import Headline from '@glise/components/common/Headline/Headline';
import Image from 'next/image';
import cn from 'classnames';
import { Grid } from '@mui/material';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { CLOUDFRONT_URL } from '@glise/utils/constants';

interface FeatureProps {
  name: string;
  title: string | ReactElement;
  description: string;
  illustration: string;
  id: number;
}

const Features: FC<FeaturesProps> = () => {
  const { t } = useTranslation('home');
  const features: FeatureProps[] = [
    {
      id: 1,
      name: t('features.cards.automation.name'),
      title: (
        <div className={styles.Title}>
          <p className={styles.Main}>
            <Trans
              i18nKey="home:features.cards.automation.title"
              components={{ br: <br /> }}
            />
          </p>
        </div>
      ),
      description: t('features.cards.automation.description'),
      illustration: `${CLOUDFRONT_URL}features/automation.webp`,
    },
    {
      id: 2,
      name: t('features.cards.templates.name'),
      title: (
        <div className={styles.Title}>
          <p className={styles.Main}>
            <Trans
              i18nKey="home:features.cards.templates.title"
              components={{ br: <br /> }}
            />
          </p>
        </div>
      ),
      description: t('features.cards.templates.description'),
      illustration: `${CLOUDFRONT_URL}features/templates.webp`,
    },
    {
      id: 3,
      name: t('features.cards.metrics.name'),
      title: (
        <div className={styles.Title}>
          <p className={styles.Main}>
            <Trans
              i18nKey="home:features.cards.metrics.title"
              components={{ br: <br /> }}
            />
          </p>
        </div>
      ),
      description: t('features.cards.metrics.description'),
      illustration: `${CLOUDFRONT_URL}features/metrics.webp`,
    },
  ];

  const [activeFeatureId, setActiveFeatureId] = useState<FeatureProps['id']>(
    features[0].id,
  );
  const featureCount = features.length;
  const activeFeatureIndex = features.findIndex(
    (featureItem) => featureItem.id === activeFeatureId,
  );

  const indicatorWidth = 100 / featureCount;
  const indicatorLeft = (activeFeatureIndex * 100) / featureCount;

  return (
    <Container>
      <div className={styles.FeaturesWrapper}>
        <Headline
          title={
            <div>
              <Trans
                i18nKey="home:features.headline.title"
                components={{ br: <br /> }}
              />
            </div>
          }
          subtitle={t('features.headline.subtitle')}
          tagline={t('features.headline.tagline')}
        />
        <div className={styles.FeaturesCardWrapper}>
          <Grid
            container
            className={styles.FeaturesTitles}
            style={
              {
                '--indicator-width': `${indicatorWidth}%`,
                '--indicator-left': `${indicatorLeft}%`,
              } as React.CSSProperties
            }
            sx={{
              justifyContent: 'center',
              gap: '1rem',
            }}
          >
            <div className={styles.ActiveIndicator} />
            {features.map((feature) => (
              <Grid
                item
                md
                xs="auto"
                key={feature.name}
                className={cn(
                  styles.FeatureTitle,
                  feature.id === activeFeatureId && styles.Active,
                )}
                onClick={() => setActiveFeatureId(feature.id)}
              >
                {feature.name}
              </Grid>
            ))}
          </Grid>
          <div
            className={cn(
              styles.SelectedFeatureWrapper,
              activeFeatureIndex === 0 && styles.FirstActive,
              activeFeatureIndex === features.length - 1 && styles.LastActive,
            )}
          >
            <div className={styles.Content}>
              {features.map((feature) => (
                <div
                  key={feature.id}
                  style={{
                    display: feature.id === activeFeatureId ? 'block' : 'none',
                  }}
                >
                  {feature.title}
                  <div className={styles.Description}>
                    {feature.description}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.SelectedFeaturePreview}>
              {features.map((feature) => (
                <Image
                  key={feature.id}
                  src={feature.illustration}
                  alt={`Preview - ${feature.name}`}
                  width={520}
                  height={464}
                  crossOrigin={'anonymous'}
                  style={{
                    display: feature.id === activeFeatureId ? 'block' : 'none',
                  }}
                  layout="responsive"
                  // quality={100}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Features;
